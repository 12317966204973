import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ViewUIPlus from "view-ui-plus";
import "view-ui-plus/dist/styles/viewuiplus.css";
import { isPC } from "./utils/common";
import "./assets/iconfont/iconfont.css";

if (
  !(
    window.innerWidth > 720 &&
    isPC() &&
    !/iOS|Android/i.test(navigator.userAgent)
  )
) {
  window.location.pathname = "/m/";
}

if (store.state.user.token) {
  store.dispatch("user/getInfo");
}

store.dispatch("public/getAreas");

const app = createApp(App);

app.config.globalProperties.$host = "https://www.huoshiedu.com";
app.config.globalProperties.$qiniu_url = "https://jlcdn.bulltech.cn";

app.use(store).use(router).use(ViewUIPlus).mount("#app");
