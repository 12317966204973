<template>
  <view
    class="my-button"
    :class="{ disabled: disabled, round: round, clickable: clickable }"
    :style="{
      width: width,
      height: height,
      backgroundColor: bgColor,
      backgroundImage: linearGradient,
      padding: padding,
      borderRadius: radius + 'px',
      color: color,
      fontWeight: weight,
      fontSize: size + 'px',
      border: borderColor ? borderWidth + 'px solid ' + borderColor : 'none',
    }"
    @click="onClick"
  >
    <slot></slot>
  </view>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    color: {
      type: String,
      default: "#000000",
    },
    weight: {
      type: String,
      default: "500",
    },
    size: {
      type: Number,
      default: 28,
    },
    bgColor: {
      type: String,
      default: "",
    },
    linearGradient: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "",
    },
    radius: {
      type: Number,
      default: 0,
    },
    spacing: {
      type: String,
      default: "0",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: "",
    },
    borderWidth: {
      type: Number,
      default: 1,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick() {
      if (!this.disabled && this.clickable) {
        this.$emit("onclick");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.clickable {
  cursor: pointer;
  &:active {
    opacity: 0.8;
  }

  &.disabled {
    opacity: 0.4;
  }

  &.disabled:active {
    opacity: 0.4;
  }
}
.my-button {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  &.round {
    border-radius: 10000rpx !important;
  }
}
</style>
