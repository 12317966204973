export const isPC = () => {
  var userAgentInfo = navigator.userAgent;
  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  var flag = true;
  for (let v = 0; v < Agents.length - 1; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
};

export function formatTime(seconds) {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = seconds % 60;

  var timeString = "";

  if (hours > 0) {
    timeString += hours + "小时 ";
  }

  if (minutes > 0) {
    timeString += minutes + "分钟 ";
  }

  if (remainingSeconds > 0) {
    timeString += remainingSeconds + "秒";
  }

  return timeString.trim();
}
