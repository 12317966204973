<template>
  <div class="layout">
    <Layout>
      <Header class="layout-header">
        <div class="layout-wrapper layout-header-wrapper">
          <div class="header-left">
            <div class="header-logo" @click="toHome">
              <img class="logoimg" src="../../assets/images/logo.jpg" />
            </div>
            <div class="header-menus">
              <div
                v-for="(menu, i) in menus"
                :key="i"
                :class="{
                  active: menu.path === this.$route.path,
                }"
                class="menu"
                @click="clickMenu(i)"
              >
                {{ menu.name }}
              </div>
            </div>
          </div>
          <div class="header-right">
            <div class="search-box">
              <input
                class="search-input"
                type="text"
                placeholder="请输入搜索关键字"
                v-model="searchVal"
                @keydown.enter="toSearch"
              />
              <Icon
                class="search-icon"
                color="#cccccc"
                :size="20"
                type="ios-search"
              />
            </div>
            <div class="login-box">
              <span v-if="!$store.state.user.token" @click="showLoginModal">
                登录/注册
              </span>
              <div class="user-info" v-else>
                <Poptip trigger="hover" width="100px" placement="bottom-end">
                  <Avatar icon="ios-person" :src="$store.state.user.avatar" />
                  <span class="user-nickname">{{
                    $store.state.user.name
                  }}</span>
                  <template #content>
                    <div class="user-menu-list">
                      <div class="user-menu-item" @click="toSetting">
                        账号设置
                      </div>
                      <div class="user-menu-item" @click="toStudyCenter">
                        学习中心
                      </div>
                      <div class="user-menu-item" @click="doLogout">
                        退出登录
                      </div>
                    </div>
                  </template>
                </Poptip>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <Layout>
        <router-view></router-view>
      </Layout>
      <Footer class="layout-footer">
        <div class="layout-footer-wrapper-new layout-wrapper">
          <a v-for="(item, index) in footerCates" :key="index">{{
            item.name
          }}</a>
        </div>
        <div class="layout-footer-wrapper-new footer-public-content">
          <a href="">客服热线：400 周一至周日 9:00 - 21:00</a>
          <a href="https://beian.miit.gov.cn/">苏ICP备2023055793号-2</a>
          <a href="">在线客服：火石教育公众号</a>
        </div>

        <!-- <div class="layout-footer-wrapper layout-wrapper">
          <div class="layout-footer-left">
            <div class="footer-cate-list">
              <div class="footer-cate-item">
                <div class="footer-cate-header">联系我们</div>
                <div class="footer-cate-content"></div>
              </div>
            </div>
          </div>
          <div class="layout-footer-right">
            <div class="footer-cate-list">
              <div
                class="footer-cate-item"
                v-for="(item, index) in footerCates"
                :key="index"
              >
                <div class="footer-cate-header">{{ item.name }}</div>
                <div class="footer-cate-content">
                  <div class="" v-for="(info, i) in item.childrens" :key="i">
                    {{ info.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </Footer>
      <LoginModal></LoginModal>
      <PosterModal :poster="posterUrl"></PosterModal>
    </Layout>
  </div>
</template>

<script>
import LoginModal from "@/components/login/login-modal.vue";
import PosterModal from "@/components/modal/poster-modal.vue";

import { fetchPosterInfo } from "@/api/public";

export default {
  components: {
    LoginModal,
    PosterModal,
  },
  data() {
    return {
      searchVal: "",
      posterUrl: "",
      menus: [
        {
          name: "主 页",
          path: "/",
        },
        {
          name: "题 库",
          path: "/question-repository",
        },
        {
          name: "课 程",
          path: "/course",
        },
        {
          name: "资 讯",
          path: "/information",
        },
        {
          name: "关于我们",
          path: "/about-us",
        },
      ],
      footerCates: [
        {
          name: "联系我们",
          childrens: [
            // {
            //   name: "文案信息",
            // },
            // {
            //   name: "文案信息",
            // },
            // {
            //   name: "文案信息",
            // },
            // {
            //   name: "文案信息",
            // },
          ],
        },
        {
          name: "法律声明",
          childrens: [
            // {
            //   name: "文案信息",
            // },
            // {
            //   name: "文案信息",
            // },
            // {
            //   name: "文案信息",
            // },
            // {
            //   name: "文案信息",
            // },
          ],
        },
        {
          name: "隐私政策",
          childrens: [
            // {
            //   name: "文案信息",
            // },
            // {
            //   name: "文案信息",
            // },
            // {
            //   name: "文案信息",
            // },
            // {
            //   name: "文案信息",
            // },
          ],
        },
        {
          name: "加入火石",
          childrens: [
            // {
            //   name: "文案信息",
            // },
            // {
            //   name: "文案信息",
            // },
            // {
            //   name: "文案信息",
            // },
            // {
            //   name: "文案信息",
            // },
          ],
        },
      ],
    };
  },
  mounted() {
    fetchPosterInfo().then((res) => {
      this.posterUrl = res.data;
    });
  },
  methods: {
    clickMenu(i) {
      this.$router.push({ path: this.menus[i].path });
    },
    doLogout() {
      this.$store.dispatch("user/logout").then(() => {
        this.$router.go(0);
      });
    },
    toStudyCenter() {
      this.$router.push("/user-study-center");
    },
    showLoginModal() {
      this.$store.commit("public/SET_LOGIN", true);
    },
    toHome() {
      this.$router.push({ path: "/" });
    },
    toSetting() {
      this.$router.push({ path: "/setting" });
    },
    toSearch() {
      if (this.searchVal) {
        this.$router.push({
          path: "/search",
          query: { keyword: this.searchVal },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout-header {
  background: #ffffff;
  padding: 0;
  height: 60px;
  min-width: 1220px;
  display: flex;
  justify-content: space-between;
  .header-left {
    flex: 1;
    display: flex;
  }
  .header-right {
    display: flex;
    .search-box {
      display: flex;
      align-items: center;
      position: relative;
      .search-input {
        border-radius: 100px;
        border: none;
        background-color: #f5f6f8;
        height: 36px;
        padding-left: 38px;
        padding-right: 14px;
        &::placeholder {
          color: #cccccc;
        }
      }

      .search-icon {
        position: absolute;
        left: 10px;
        top: 21px;
      }
    }

    .login-box {
      display: flex;
      align-items: center;
      margin-left: 30px;
      cursor: pointer;
      font-size: 17px;
      font-weight: bold;

      .user-info {
        .user-nickname {
          margin-left: 10px;
        }

        .user-menu-list {
          width: 76px;
          .user-menu-item {
            height: 30px;
            line-height: 30px;
            &:hover {
              color: #00737c;
            }
          }
        }
      }
    }
  }

  .layout-header-wrapper {
    height: 100%;
    display: flex;

    .header-logo {
      width: 250px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      .logoimg {
        height: 80%;
      }

      .header-logo-name {
        color: #000;
        margin-left: 20px;
      }
    }

    .header-menus {
      display: flex;
      align-items: center;
      .menu {
        font-size: 18px;
        height: 40px;
        text-align: center;
        margin: 0 25px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        &.active {
          color: #00737c;
          border-bottom: 3px solid #00737c;
        }
      }
    }
  }
}

.layout-wrapper {
  width: 1220px;
  min-width: 1220px;
  margin: 0 auto;
}

.layout-footer {
  background-color: #0d0f1b;
  padding: 0;

  .layout-footer-wrapper-new {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 86px;
    padding: 34px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    a {
      flex-shrink: 0;
      opacity: 0.8;
      font-size: 16px;
      color: #dfdfdf;
      cursor: pointer;
      padding: 0px 30px;
      border-right: 1px solid #6d6d6d;
    }

    a:last-child {
      border-right: 0px;
    }

    // .footer-split-line {
    //   flex-shrink: 0;
    //   background: #6d6d6d;
    //   width: 1px;
    //   height: 18px;
    //   margin: 0 30px;
    // }
  }

  .footer-public-content {
    background-color: #333;
    height: 37px;
    padding: 30px 0;
    a {
      font-size: 12px;
    }
  }

  .layout-footer-wrapper {
    margin: 50px auto 92px;
    display: flex;
    .layout-footer-left {
      flex: 1;
    }
    .layout-footer-left,
    .layout-footer-right {
      width: 870px;
      .footer-cate-list {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .footer-cate-item {
          .footer-cate-header {
            width: 80px;
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 28px;
          }

          .footer-cate-content {
            margin-top: 24px;
            max-height: 196px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            div {
              width: 56px;
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
