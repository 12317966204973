import axios from "axios"; // 引入axios
import { emitter } from "@/utils/bus.js";
import router from "@/router/index";
import store from "@/store";
import { Message } from "view-ui-plus";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 99999,
});
let acitveAxios = 0;
let timer;
const showLoading = () => {
  acitveAxios++;
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    if (acitveAxios > 0) {
      emitter.emit("showLoading");
    }
  }, 400);
};

const closeLoading = () => {
  acitveAxios--;
  if (acitveAxios <= 0) {
    clearTimeout(timer);
    emitter.emit("closeLoading");
  }
};
// http request 拦截器
service.interceptors.request.use(
  (config) => {
    if (!config.donNotShowLoading) {
      showLoading();
    }
    config.headers = {
      "Content-Type": "application/json",
      ...config.headers,
    };
    if (store.state.user.token) {
      config.headers.token = `Bearer ${store.state.user.token}`;
    }
    return config;
  },
  (error) => {
    if (!error.config.donNotShowLoading) {
      closeLoading();
    }
    Message.error(error);
    return error;
  }
);

// http response 拦截器
service.interceptors.response.use(
  (response) => {
    if (!response.config.donNotShowLoading) {
      closeLoading();
    }
    if (response.data.code === 1) {
      if (response.headers.msg) {
        response.data.msg = decodeURI(response.headers.msg);
      }
      return response.data;
    } else if (response.data.code === 103) {
      localStorage.clear();
      store.commit("public/SET_LOGIN", true);
      return response.data.message ? response.data : response;
    } else {
      Message.error(response.data.message);
      return response.data.message ? response.data : response;
    }
  },
  (error) => {
    if (!error.config.donNotShowLoading) {
      closeLoading();
    }

    if (!error.response) {
      Message.error(`检测到请求错误${error}`);
      return;
    }

    switch (error.response.status) {
      case 500:
        Message.error(`检测到接口错误${error}`);
        localStorage.clear();
        router.push({ name: "Login", replace: true });
        break;
      case 404:
        Message.error(`检测到接口错误${error}`);
        break;
    }

    return error;
  }
);

export default service;
