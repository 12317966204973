import request from "@/utils/request";

export const sendLoginMsg = (data) => {
  return request({
    url: "/user/sendcodeReg",
    method: "post",
    data,
  });
};

export const getInfo = () => {
  return request({
    url: "/user/info",
    method: "get",
  });
};

export const login = (data) => {
  return request({ url: "/user/loginH5", method: "post", data });
};

export const logout = () => {
  return request({ url: "/user/logout", method: "get" });
};

export const getUserCourse = (params) => {
  return request.get("order/index", params);
};

export const getTeacherInfo = (params) => {
  return request({ url: "/user/IsFollowTeacher", method: "get", params });
};

export const followTeacher = (data) => {
  return request({ url: "/user/FollowTeacher", method: "post", data });
};

export const unfollowTeacher = (data) => {
  return request({ url: "/user/UnfollowTeacher", method: "post", data });
};
