import { fetchProvinceList } from "@/api/public";

const state = {
  showLoginModal: false,
  showPosterModal: false,
  provinceList: [],
  curProvinceId: 0,
};

if (window.localStorage.getItem("huoshicurProvinceId")) {
  state.curProvinceId = Number(
    window.localStorage.getItem("huoshicurProvinceId")
  );
}

const mutations = {
  SET_LOGIN: (state, status) => {
    state.showLoginModal = status;
  },
  SET_POSTER_VISIABLE: (state, status) => {
    state.showPosterModal = status;
  },
  SET_PROVINCE_LIST: (state, list) => {
    state.provinceList = list;
  },
  SET_PROVINCE: (state, id) => {
    state.curProvinceId = id;
    window.localStorage.setItem("huoshicurProvinceId", id);
  },
};

const actions = {
  // user login
  getAreas({ commit }) {
    return new Promise((resolve, reject) => {
      fetchProvinceList()
        .then((response) => {
          if (response.code === 1) {
            const { data } = response;
            commit("SET_PROVINCE_LIST", data);
            if (window.localStorage.getItem("huoshicurProvinceId")) {
              state.curProvinceId = Number(
                window.localStorage.getItem("huoshicurProvinceId")
              );
            } else if (data.length > 0) {
              commit("SET_PROVINCE", data[0].id);
            }
            resolve(data);
          } else {
            reject(response.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
