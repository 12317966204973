<template>
  <router-view />
</template>

<style lang="less">
@import "@/assets/css/reset.css";

* {
  margin: 0;
  padding: 0;
}

div {
  font-family: "Microsoft Yahei", "PingFang SC", "Hiragino Sans GB", "STHeiti",
    "Microsoft Sans Serif", sans-serif;
}

.main-container {
  width: 1220px;
  margin: 30px auto 0;
}
.section-item {
  margin-bottom: 40px;
}

.confirm-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.confirm-modal.ivu-modal {
  top: 0;
}
</style>
