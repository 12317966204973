import { login, getInfo } from "@/api/user";

const state = {
  token: "",
  name: "",
  avatar: "",
  phone: "",
  id: "",
};

if (window.localStorage.getItem("token")) {
  state.token = window.localStorage.getItem("token");
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo)
        .then((response) => {
          if (response.code === 1) {
            const { data } = response;
            commit("SET_TOKEN", data.token);
            window.localStorage.setItem("token", data.token);
            const { uid, nickname, face, mobile } = data.userInfo;

            commit("SET_ID", uid);
            commit("SET_NAME", nickname);
            commit("SET_AVATAR", face);
            commit("SET_PHONE", mobile);
            resolve(data);
          } else {
            reject(response.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((response) => {
          const { code, data } = response;

          if (code !== 1 && String(code).substring(0, 1) !== "2") {
            reject(new Error("登录失败"));
          }

          const { uid, nickname, face, mobile } = data;

          commit("SET_ID", uid);
          commit("SET_NAME", nickname);
          commit("SET_AVATAR", face);
          commit("SET_PHONE", mobile);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      commit("SET_ID", "");
      commit("SET_TOKEN", "");
      commit("SET_ROLE", "");
      commit("SET_NAME", "");
      commit("SET_AVATAR", "");
      commit("SET_PHONE", "");
      // removeToken()
      window.localStorage.clear();
      resolve();
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_ROLE", "");
      window.localStorage.clear();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
